import logo from './logo.svg';
import './App.css';
import * as fabric from 'fabric'; // v6
import { useState, useRef,useEffect } from 'react';
import DraggableList from "react-draggable-list";
import {Row,Col,Container,Modal,Button} from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HexColorPicker } from "react-colorful";



const base_url="https://sboedtemplateapi.uvanij.com/api/Animation/";

function App() {
  const canvaref = useRef(null);
  const [canvas, setCanvas] = useState(null);

  //Default {version: '6.0.2', objects: []}

  const [templateId, setTemplateId] = useState(null);

  const [templateJSON,setTemplateJSON] = useState(null);

  const [templateName, setTemplateName] = useState(null);

  const [templates, setTemplates] =useState([]);

  const [saveModal,setSaveModal] = useState(false);
  
  const [textModal,setTextModal] =  useState(false);

  const [selected,setSelected] = useState(false);

  const defaultText={fontFamily:'arial',fontWeight:'normal',fontSize:10,fill:'#000000',angle:0.0,textAlign:"center"};

  const [textFont,setTextFont] = useState(defaultText);
  
  const [imageModal,setImageModal] = useState(false);
  
  const defaultImage={"angle":0,"opacity":100};

  const [imageProp,setImageProp] = useState(defaultImage);


  const containerRef = useRef();

  const _onListChange = (newList) => {
    let json=canvas.toJSON(['idd']);
    json.objects=JSON.parse(JSON.stringify(newList));
    setTemplateJSON(json);
    canvas.clear();
    canvas.loadFromJSON(json, function() {
      canvas.renderAll.bind(canvas);
    });
  };

  const Item = ({ item, itemSelected, dragHandleProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
  
    return (
      <div
        className="disable-select"
        style={{
          border: "1px solid black",
          margin: "4px",
          padding: "10px",
          display: "flex",
          justifyContent: "space-around",
          background: "#fff",
          userSelect: "none"
        }}
      >
        Layer {item.idd} ({item.type=='IText'?'text':'image'}){canvas.getActiveObject() && canvas.getActiveObject().get('idd')==item.idd?'(Selected)':''}
        <div
          className="disable-select dragHandle"
          style={{
            fontWeight: "600",
            transform: "rotate(90deg)",
            width: "20px",
            height: "20px",
            backgroundColor: "black"
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            console.log("touchStart");
            e.target.style.backgroundColor = "blue";
            document.body.style.overflow = "hidden";
            onTouchStart(e);
          }}
          onMouseDown={(e) => {
            console.log("mouseDown");
            document.body.style.overflow = "hidden";
            onMouseDown(e);
          }}
          onTouchEnd={(e) => {
            e.target.style.backgroundColor = "black";
            document.body.style.overflow = "visible";
          }}
          onMouseUp={() => {
            document.body.style.overflow = "visible";
          }}
        ></div>
      </div>
    );
  };

  

  const fontFamilyArray=['Arial','Arial Black','Bahnschrift','Calibri','Cambria','Cambria Math','Candara','Comic Sans MS','Consolas','Constantia','Corbel','Courier New','Ebrima','Franklin Gothic Medium','Gabriola','Gadugi','Georgia','HoloLens MDL2 Assets','Impact','Ink Free','Javanese Text','Leelawadee UI','Lucida Console','Lucida Sans Unicode','Malgun Gothic','Marlett','Microsoft Himalaya','Microsoft JhengHei','Microsoft New Tai Lue','Microsoft PhagsPa','Microsoft Sans Serif','Microsoft Tai Le','Microsoft YaHei','Microsoft Yi Baiti','MingLiU-ExtB','Mongolian Baiti','MS Gothic','MV Boli','Myanmar Text','Nirmala UI','Palatino Linotype','Segoe MDL2 Assets','Segoe Print','Segoe Script','Segoe UI','Segoe UI Historic','Segoe UI Emoji','Segoe UI Symbol','SimSun','Sitka','Sylfaen','Symbol','Tahoma','Times New Roman','Trebuchet MS','Verdana','Webdings','Wingdings','Yu Gothic'];

  useEffect(()=>{
      const canva = new fabric.Canvas(canvaref.current,{
        "preserveObjectStacking":true
      });
      canva.on("selection:created", function(obj){
        if(canva.getActiveObject().get('type')=='i-text')
        {
          let fontFamily={
          fontFamily:canva.getActiveObject().fontFamily,
          fontWeight:canva.getActiveObject().fontWeight,
          fontSize:canva.getActiveObject().fontSize,
          angle:canva.getActiveObject().angle,
          fill:canva.getActiveObject().fill,
          textAlign:canva.getActiveObject().textAlign
          }
          setTextFont(fontFamily);
          setSelected(true);
          setTextModal(true);
        }
        if(canva.getActiveObject().get('type')=='image')
        {
          let fontFamily={
          angle:canva.getActiveObject().angle,
          opacity:canva.getActiveObject().opacity*100
          }
          console.log("fontFamily",fontFamily);
          setImageProp(fontFamily);
          setImageModal(true);
        }
      });
      
      setCanvas(canva);
      fetchTemplates();
      return () => {
        setCanvas(null);
        canva.dispose();
      }
  },[]);

  useEffect(()=>{
      let temp1=JSON.parse(JSON.stringify(templateJSON));
      if(canvas && canvas.getActiveObject()!=undefined && temp1 && temp1.objects.length>0)
      {
        let temp=canvas.toJSON(['idd']);
        let temp1=JSON.parse(JSON.stringify(templateJSON));
        let filterIndex=null;
        temp1.objects.map((t,index)=>t.idd==canvas.getActiveObject().get('idd')?filterIndex=index:'');
        if(filterIndex)
        {
          temp1.objects[filterIndex]={...temp.objects[filterIndex],idd:canvas.getActiveObject().get('idd')};
        }
        setTemplateJSON(temp1);
      }
  },[JSON.parse(JSON.stringify(canvas?.getActiveObject() || {}))])


  const fetchTemplates=()=> {
    axios.post(`${base_url}GetUserTemplateData`,{
      "UserTemplateId":"",
      "UserId":1
      }).then(res => {
          if(res.data.isSuccess)
          {
            setTemplates(res.data.data);
          }
          else
          {
            setTemplates([]);
          }
     }).catch(error =>  toast.error("Something Went Wrong!"))
  }

  const openTemplate=(t)=>{
    setTemplateId(t.userTemplateId);
    setTemplateName(t.templateName);
    var json = JSON.parse(t.templateJson);
    json.objects.map((j,index)=>!j.idd?j.idd=String(index+1):'');
    setTemplateJSON(json);
    console.log("json",json)
    canvas.clear();
    canvas.loadFromJSON(json, function() {
    canvas.renderAll.bind(canvas);
  });
  }

  const openNewTemplate=()=>{
    setTemplateId(null);
    setTemplateJSON(null);
    setTemplateName(null);
    canvas.clear();
  }

  const saveTemplate=()=>{
    axios.post(`${base_url}AddEditUserTemplate`,
      {
         "UserTemplateId": templateId || '',
         "TemplateName": templateName,
         "TemplateType": "Email",
         "TemplateJson": JSON.stringify(canvas.toJSON(['idd'])),
         "UserId": 1,
         "CreatedBy": 1,
         "UpdatedBy": 2,
         "IsActive": true
        }
        ).then(res => {
          console.log(res)
          if(res.data.isSuccess)
          {
            toast.success(res.data.data.result_message);
            setSaveModal(false);
            fetchTemplates();
          }
          else
          {
            toast.error("Something went wrong");
          }
     }).catch(error =>  toast.error("Something Went Wrong!"))
  }

  const grow=()=> {
    var valueX = Math.round(Math.pow(13, 2));
    var valueY = Math.round(Math.pow(13, 2));

    canvas.getActiveObject().animate(
      { height: valueY, width: valueX },
      {
        duration: 1000,
        easing: fabric.util.ease.easeInSine,
        onChange: canvas.renderAll.bind(canvas),
      }
    );
  }

  const shrink=()=> {
    var shrinkX = Math.round(canvas.getActiveObject().getScaledWidth() / 3);
    var shrinkY = Math.round(canvas.getActiveObject().getScaledHeight() / 3);
    canvas.getActiveObject().animate(
      { height: shrinkY, width: shrinkX },
      {
        duration: 1600,
        easing: fabric.util.ease.easeInSine,
        onChange: canvas.renderAll.bind(canvas),
      }
    );
  }

  const animateObject=()=>{
    grow();
    setTimeout(()=>shrink(),
  2000);
  }
  




  const addElement = (img) => {
   let reader=new FileReader();
   reader.readAsDataURL(img);
   reader.onload=(e)=>{
    let imageUrl=e.target.result;
      let imageElement=document.createElement('img');
      imageElement.src=imageUrl;
      imageElement.onload=()=>{
        let json=canvas.toJSON(['idd']);
        let image=new fabric.Image(imageElement,{idd:String((json.objects?.length || 0)+1)});
        console.log("image",image);
        canvas.add(image);
        canvas.centerObject(image);
        canvas.setActiveObject(image);
        canvas.renderAll();
        console.log("getactiveobject",canvas.getActiveObject().get('idd'));
        let temp=canvas.toJSON(['idd']);
        console.log("TEMP_DEEP_1",canvas);
        console.log("temp_deep",temp)
        let temp1=JSON.parse(JSON.stringify(templateJSON));
        if(!temp1)
        {
          temp1={...temp,objects:[]}
        }
        temp1.objects.push({...temp.objects[temp.objects.length-1],idd:canvas.getActiveObject().get('idd')});
        setTemplateJSON(temp1);
      }
   } 
    
  }

  
  const addText=()=>{
    let json=canvas.toJSON(['idd']);
    if(!selected)
    {
      let temp1=JSON.parse(JSON.stringify(templateJSON));
      let text = new fabric.IText('Test', {
      evented: true,
      selectable: true,
      editable: true,
      fontFamily: textFont.fontFamily,
      fontWeight:textFont.fontWeight,
      fontSize:textFont.fontSize,
      angle:textFont.angle,
      fill:textFont.fill,
      textAlign:textFont.textAlign,
      idd:String((json.objects?.length || 0)+1)
      
      });
    
    canvas.add(text);
    canvas.renderAll();
    let temp=canvas.toJSON(['idd']);
    if(!temp1)
    {
      temp1={...temp,objects:[]}
    }
    temp1.objects.push({...temp.objects[temp.objects.length-1],idd:String((json.objects?.length || 0)+1)});
    setTextFont(defaultText);
    setTemplateJSON(temp1);
    }
    else
    {
      canvas.getActiveObject().fontFamily=textFont.fontFamily;
      canvas.getActiveObject().fontWeight=textFont.fontWeight;
      canvas.getActiveObject().fontSize=textFont.fontSize;
      canvas.getActiveObject().angle=textFont.angle;
      canvas.getActiveObject().fill=textFont.fill;
      canvas.getActiveObject().textAlign=textFont.textAlign;
      canvas.renderAll();
      setTimeout(()=>{
        let temp1=JSON.parse(JSON.stringify(templateJSON)); 
        let temp=canvas.toJSON(['idd']);
      let filterIndex=null;
      temp1.objects.map((t,index)=>t.idd==canvas.getActiveObject().get('idd')?filterIndex=index:'');
      if(filterIndex)
      {
        temp1.objects[filterIndex]={...temp.objects[filterIndex],idd:canvas.getActiveObject().get('idd')};
      }
      setTemplateJSON(temp1);
      },500);
    }
    setTextModal(false);
    setSelected(false);
  }

  const updateImage=()=>{

    canvas.getActiveObject().angle=imageProp.angle;
    canvas.getActiveObject().opacity=imageProp.opacity/100;
    canvas.renderAll();
    setTimeout(()=>{
    let temp1=JSON.parse(JSON.stringify(templateJSON));
    let temp=canvas.toJSON(['idd']);
    let filterIndex=null;
    temp1.objects.map((t,index)=>t.idd==canvas.getActiveObject().get('idd')?filterIndex=index:'');
    if(filterIndex)
    {
      temp1.objects[filterIndex]={...temp.objects[filterIndex],idd:canvas.getActiveObject().get('idd')};
    }
    setTemplateJSON(temp1);
    },500);
    setImageModal(false);
  }


  const download=()=>{
    if(canvas.toJSON(['idd']).objects.length==0)
    {
      alert("Please add any elements to the below canvas to download pic");
      return false;
    }
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'CanvasAsImage.png');
    let dataURL = canvas.toDataURL('image/png');
    let url = dataURL.replace(/^data:image\/png/,'data:application/octet-stream');
    downloadLink.setAttribute('href', url);
    downloadLink.click();
  }

  const consoleJSON=()=>{
    console.log(canvas.toJSON(['idd']));
    alert(JSON.stringify(canvas.toJSON(['idd'])));
  }

  const closeModal=()=>{
    setSaveModal(false);
  }

  const deleteObject=()=>{
    let temp1=JSON.parse(JSON.stringify(templateJSON));
    let temp={...temp1,objects:[]};
    let filterIndex=null;
    temp1.objects.map((t,index)=>t.idd==canvas.getActiveObject().get('idd')?filterIndex=index:'');
    if(filterIndex)
    {
      temp1.objects.map((t,index)=>index!=filterIndex?temp.objects.push(t):'')
    }
    console.log("temp_delete",temp);
    setTemplateJSON(temp);
    canvas.remove(canvas.getActiveObject());
  }

  const setFontData=(value,label)=>{
    let temp=JSON.parse(JSON.stringify(textFont));
    temp[label]=value;
    setTextFont(temp);
  }

  const setImageData=(value,label)=>{
    let temp=JSON.parse(JSON.stringify(imageProp));
      temp[label]=value
    setImageProp(temp);
  }

  return (
    <Container className="container-fluid">
      <Row style={{alignItems:"center"}}>
      <Col md={3} xs={3} style={{float:"left",width:"30%"}}>
      <br/>
      {
          templates.map((t)=>{
            return (
              <>
                <u onClick={()=>openTemplate(t)} style={{cursor:"pointer"}} >{t.templateName}</u>
                <br/>
                <br/>
              </>
            )
          })
      }
      
      <u onClick={()=>openNewTemplate()} style={{cursor:"pointer"}}>New Template</u>
      </Col>
      <Col md={3} xs={3} style={{float:"left",width:"30%"}}>
      <h1>{templateId?templateName:"Working On New Template"}</h1>
      <input type="file" name="myImage" accept=".jpg,.png,.jpeg" onChange={(e)=>addElement(e.target.files[0])} />
      <button  id="link" name="toJSON"  onClick={()=>consoleJSON()}>Convert to JSON </button>
      <button  id="link" name="download"  onClick={()=>download()}>Download Poster </button>
      <button  id="link" name="download"  onClick={()=>setTextModal(true)}>Add Text </button>
      <button  id="link" name="download"  onClick={()=>setSaveModal(true)}>Save Template </button>
      <button  id="link" name="download"  onClick={()=>openNewTemplate()}>New Template </button>
      <button  id="link" name="download"  onClick={()=>deleteObject()}>Delete Selected Object </button>
      <button  id="link" name="download"  onClick={()=>animateObject()}>Add Animation to Selected Object </button>
      </Col>
      <Col md={3} xs={3} style={{float:"right",width:"60%"}}>
      <div style={{ border:"1px dotted gray"}}>
        <canvas
          width="600"
          height="600"
          ref={canvaref}
        />
      </div>
      </Col>

      <Col md={3} xs={3} style={{float:"right",width:"60%"}}>
      {templateJSON && templateJSON.objects.length>0 &&
      <div className="App">
        <div
          ref={containerRef}
          style={{ touchAction: "pan-y", background: "beige" }}
        >
          <DraggableList
            itemKey="idd"
            template={Item}
            list={templateJSON.objects}
            onMoveEnd={(newList) => _onListChange(newList)}
            container={() => containerRef.current}
          />
          {/* {list.map((item) => (
            <Item item={item} />
          ))} */}
        </div>
      </div>
      }
      </Col>
      </Row>
    
      <ToastContainer />
    { saveModal?
     <div
     className="modal show"
     style={{ display: 'block', position: 'initial' }}
   >
      <Modal show={saveModal} onHide={()=>setSaveModal(false)}>
        <Modal.Header>
          <Modal.Title>{templateId?templateName:'Saving New Template'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Please enter the template name to save
          <input type="text" id="templateName" name="templateName" onChange={(e)=>setTemplateName(e.target.value)} value={templateName}/>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={()=>closeModal()}>Close</Button>
          <Button variant="primary" onClick={()=>saveTemplate()}>Save changes</Button>
        </Modal.Footer>
      </Modal></div>:''}


      { textModal?
     <div
     className="modal show"
     style={{ display: 'block', position: 'initial' }}
   >
      <Modal show={textModal} onHide={()=>{setTextModal(false);setSelected(false)}}>
        <Modal.Header>
          <Modal.Title>{"Add New Text"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Please select the Font Family to render
          <select value={textFont.fontFamily} onChange={(e)=>setFontData(e.target.value,'fontFamily')}>
          {fontFamilyArray.map((f)=><option value={f.toLowerCase()}>{f}</option>)}
          </select>
          Please select the Font Size to render
          <input type="number" name="fontSize" list="fontSize" value={textFont.fontSize} onChange={(e)=>setFontData(e.target.value,'fontSize')}/>
          Please select the Font Weight to render
          <select value={textFont.fontWeight} onChange={(e)=>setFontData(e.target.value,'fontWeight')}>
          <option value={"normal"}>{"Normal"}</option>
          <option value={"bold"}>{"Bold"}</option>
          </select>
          Please select the Text Align
          <select value={textFont.fontWeight} onChange={(e)=>setFontData(e.target.value,'textAlign')}>
          <option value={"left"}>{"Left"}</option>
          <option value={"center"}>{"Center"}</option>
          <option value={"right"}>{"Right"}</option>
          <option value={"justify"}>{"Justify"}</option>
          <option value={"justify-left"}>{"Justify-left"}</option>
          <option value={"justify-center"}>{"Justify-center"}</option>
          <option value={"justify-right"}>{"Justify-right"}</option>
          </select>
          Please select the Color
          <HexColorPicker color={textFont.fill} onChange={(e)=>setFontData(e,'fill')} /> 
          Please select the angle
          <input type="number" name="angle" list="angle" value={textFont.angle} onChange={(e)=>setFontData(e.target.value,'angle')}/>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setTextFont(defaultText);setTextModal(false);setSelected(false)}}>Close</Button>
          <Button variant="primary" onClick={()=>addText()}>Save Text</Button>
        </Modal.Footer>
      </Modal></div>:''}


      { imageModal?
     <div
     className="modal show"
     style={{ display: 'block', position: 'initial' }}
   >
      <Modal show={imageModal} onHide={()=>{setImageModal(false);setSelected(false)}}>
        <Modal.Header>
          <Modal.Title>{"Image Properties"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Please select the angle
          <input type="number" name="angle" list="angle" value={imageProp.angle} onChange={(e)=>setImageData(e.target.value,'angle')}/>
          <br/>
          Opacity
          <input type="number" name="opacity" list="opacity" value={imageProp.opacity} onChange={(e)=>setImageData(e.target.value,'opacity')}/>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setImageProp(defaultImage);setImageModal(false);}}>Close</Button>
          <Button variant="primary" onClick={()=>updateImage()}>Save Image</Button>
        </Modal.Footer>
      </Modal></div>:''}

    </Container>
  );
}

export default App;
